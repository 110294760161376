(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

/**
 * Track PLP tile impressions
 * Pull data from window.siteGaData
 * Filter duplicates out that have already been reported
 * Use that data to populate the 'impressions'
 * Add data to the dataLayer which results in a GTM tag being fired
 * Track these impressions to prevent duplicate reporting
 */
function productTiles() {
    if (!window.dataLayer || !window.siteGaData) { return; }

    siteGaData.allProducts = siteGaData.allProducts || {};

    // refer to allProducts to prevent duplicate reporting
    var productsToReport = siteGaData.products.filter(function(product) {
        var uniqueID = getImpressionUniqueID(product);
        return !siteGaData.allProducts.hasOwnProperty(uniqueID);
    });

    if (!productsToReport.length) { return; }

    var gaData = {
        'event': 'impressions',
        'ecommerce': {
            'currencyCode': siteGaData.currency,
            'impressions': productsToReport
        }
    };

    window.dataLayer.push(gaData);

    // add reported products to allProducts to prevent these from being duplicated
    productsToReport.forEach(function(product) {
        var uniqueID = getImpressionUniqueID(product);
        siteGaData.allProducts[uniqueID] = product;
    });

    // when page loads, iterate over sub grid products and report those.
    // delete the nodes to prevent duplicate reporting as this function
    // is called recursively
    var subGridProducts = $('.site-ga-productgriddata').not('.tracked-ua');
    if (subGridProducts && subGridProducts.length) {
        var currentGrid = subGridProducts.first();
        var productTilesData = {};

        try {
            productTilesData = JSON.parse(currentGrid.text());
        } catch(e) {
            log('product tiles subgrid GA data could not be serialized for analytics');
            return;
        }

        if (productTilesData && productTilesData.products) {
            siteGaData.products = productTilesData.products;
            // tag this node as tracked to prevent infinite loop of events
            currentGrid.addClass('tracked-ua');
            productTiles();
        }
    }
}

/**
 * Track PLP tile impressions loaded via Ajax
 * Usually happens in sorting, filtering, refining, or infinite scroll
 * Query the DOM for JSON generated in Search-Show Controller
 * Use the data to overwrite 'siteGaData.products'
 * Call productTiles() function
 */
function productTilesAjax() {
    var productTilesData = {};

    try {
        productTilesData = JSON.parse($('.site-ga-productgriddata').last().text());
    } catch(e) {
        log('product tiles ajax GA data could not be serialized for analytics');
        return;
    }

    if (productTilesData && productTilesData.products) {
        siteGaData.products = productTilesData.products;
        productTiles();
    }
}

/**
 * Report Product Tile click impressions
 * @param {Event} event custom event
 * @param {HTML} tile tile element, with data attribute 'itemid'
 */
function productTileClick(event, tile) {
    if (!window.dataLayer || !window.siteGaData) { return; }

    var productID = tile.dataset.itemid;
    if (!productID) { return; }

    var productToReport = siteGaData.allProducts[productID];
    if (!productToReport) { return; }

    window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'currencyCode': siteGaData.currency,
            'click': {
                'actionField': {
                    'list': productToReport.list
                },
                'products' : [ productToReport ]
            }
        }
    });
}

/**
 * Track product detail page view
 * Pull data from window.siteGaData
 */
function productDetail() {
    if (!window.dataLayer || !window.siteGaData) { return; }

    window.dataLayer.push({
        'event': 'detail',
        'ecommerce': {
            'currencyCode': siteGaData.currency,
            'detail': {
                'products': siteGaData.products
            }
        }
    });
}

/**
 * Track add to cart ecommerce event
 * Query the DOM for JSON generated in Cart-AddProduct Controller
 * Use the data to populate the 'products'
 * Add data to the dataLayer which results in a GTM tag being fired
 */
function addToCart() {
    if (!window.dataLayer) { return; }

    var addToCartData = {};

    try {
        addToCartData = JSON.parse($('.site-ga-addtocartdata').text());
    } catch(e) {
        log('add to cart GA data could not be serialized for analytics');
        return;
    }

    if (!addToCartData || !addToCartData.products) { return; }

    window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': siteGaData.currency,
            'add': {
                'products': addToCartData.products
            }
        }
    });
}

/**
 * Track remove from cart ecommerce event
 * Pull data from window.siteGaData
 */
function removeFromCart() {
    if (!window.dataLayer || !window.siteGaData) { return; }

    window.dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
            'currencyCode': siteGaData.currency,
            'remove': {
                'products': siteGaData.removeProducts
            }
        }
    });
}

/**
 * Track checkout steps
 * Pull data from window.siteGaData
 */
function checkoutStep() {
    if (!window.dataLayer || !window.siteGaData || !window.siteGaData.checkout) { return; }

    window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
            'currencyCode': siteGaData.currency,
            'checkout': {
                'actionField': siteGaData.checkout.actionField,
                'products': siteGaData.products
            }
        }
    });
}

/**
 * Track products in an order and the order transaction
 * Pull data from window.siteGaData
 * Use that data to populate the 'products' and 'purchase'
 * Add data to the dataLayer which results in a GTM tag being fired
 */
function purchase() {
    if (!window.dataLayer) { log('dataLayer does not exist to push order data to'); }
    if (!window.siteGaData) { log('siteGa data does not exist or could not be serialized'); }

    if (!window.dataLayer || !window.siteGaData) {
        return;
    }

    try {
        var gaData = {
            'event': 'transaction',
            'ecommerce': {
                'currencyCode': siteGaData.currency,
                'purchase': {
                    'actionField': siteGaData.order,
                    'products': []
                },
			},
			'paymentMethod': siteGaData.paymentMethod
        };

        // any post-processing of the products can be done in
        // this loop before pushing to the dataLayer
        window.siteGaData.products.forEach(function(product) {
            gaData.ecommerce.purchase.products.push(product);
        });

        window.dataLayer.push(gaData);
    } catch(e) {
        if (siteGaData && siteGaData.order && siteGaData.order.id) {
            log('failed to track purchase for orderNo: ' + siteGaData.order.id, e);
        } else {
            log('failed to track purchase', e);
        }
    }
}

/**
 * @private
 * Get a unique ID of the product impression
 * This is a utility function to use when determining when to report
 * impressions and when to filter out duplicates
 * @param {Object} product impression object to generate unique ID
 */
function getImpressionUniqueID(product) {
    return product.variant.length ? product.variant : product.id;
}

/**
 * @private
 * Utility log message, send to server to log
 * @param {String} msg message to send to log
 * @param {Object} data object
 * @param {String} level 'warn', 'error', or 'debug'
 */
function log(msg, data, level) {
    var payload = {
        msg: msg,
        data: JSON.stringify(data) || '',
        level: level || 'warn'
    };

    $.ajax({
        method: 'POST',
        dataType: 'json',
        url: window.siteGaData.resources.logUrl,
        async: true,
        data: payload
    });
}

module.exports = {
    productTiles: productTiles,
    productTilesAjax: productTilesAjax,
    productTileClick: productTileClick,
    productDetail: productDetail,
    addToCart: addToCart,
    removeFromCart: removeFromCart,
    checkoutStep: checkoutStep,
    purchase: purchase
};

},{}],2:[function(require,module,exports){
'use strict';

/**
 * Track PLP tile impressions
 * Pull data from window.siteGaData
 * Filter duplicates out that have already been reported
 * Use that data to populate the 'view_item_list' event
 */
function view_item_list() {
    if (!window.dataLayer || !window.siteGaData) { return; }

    siteGaData.allProductsGA4 = siteGaData.allProductsGA4 || {};

    // check allProducts to prevent duplicate reporting
    var productsToReport = siteGaData.products.filter(function(product) {
        var uniqueID = getImpressionUniqueID(product);
        return !siteGaData.allProductsGA4.hasOwnProperty(uniqueID);
    });

    if (!productsToReport.length) { return; }

    // add reported products to allProducts to prevent them from being duplicated
    var productViews = [];
    productsToReport.forEach(function(product) {
        var uniqueID = getImpressionUniqueID(product);
        siteGaData.allProductsGA4[uniqueID] = product;
        productViews.push(getProductView(product));
    });

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            items: productViews
        }
    });

    // when page loads, iterate over sub grid products and report those.
    var subGridProducts = $('.site-ga-productgriddata').not('.tracked-ga4');
    if (subGridProducts && subGridProducts.length) {
        var currentGrid = subGridProducts.first();
        var productTilesData = {};

        try {
            productTilesData = JSON.parse(currentGrid.text());
        } catch(e) {
            log('product tiles subgrid GA data could not be serialized for analytics');
            return;
        }

        if (productTilesData && productTilesData.products) {
            siteGaData.products = productTilesData.products;
            // tag this node as tracked to prevent infinite loop of events
            currentGrid.addClass('tracked-ga4');
            view_item_list();
        }
    }
}

/**
 * Track PLP tile impressions loaded via Ajax
 * Usually happens in sorting, filtering, refining, or infinite scroll
 * Query the DOM for JSON generated in Search-Show Controller
 * Use the data to overwrite 'siteGaData.products'
 * Call productTiles() function
 */
function productTilesAjax() {
    var productTilesData = {};

    try {
        productTilesData = JSON.parse($('.site-ga-productgriddata').last().text());
    } catch(e) {
        log('product tiles ajax GA data could not be serialized for analytics');
        return;
    }

    if (productTilesData && productTilesData.products) {
        siteGaData.products = productTilesData.products;
        view_item_list();
    }
}

/**
 * Report Product Tile click impressions
 * @param {Event} event custom event
 * @param {HTML} tile tile element, with data attribute 'itemid'
 */
function select_item(event, tile) {
    if (!window.dataLayer || !window.siteGaData) { return; }

    var productID = tile.dataset.itemid;
    if (!productID) { return; }

    var productToReport = siteGaData.allProductsGA4[productID];
    if (!productToReport) { return; }

    var productView = getProductView(productToReport);

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            items: [ productView ]
        }
    });
}

/**
 * Track product detail page view
 * Pull data from window.siteGaData
 */
function view_item() {
    if (!window.dataLayer || !window.siteGaData || !siteGaData.products || !siteGaData.products[0]) { return; }

    var productView = getProductView(siteGaData.products[0]);

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
            items: [ productView ]
        }
    });
}

/**
 * Track product detail page quickview from the PLP
 * Pull data from window.siteGaData
 */
function quickview() {
    if (!window.dataLayer || !window.siteGaData) { return; }

    var quickviewData = {};

    try {
        quickviewData = JSON.parse($('.site-ga-quickviewdata').text());
    } catch(e) {
        log('add to cart GA data could not be serialized for analytics');
        return;
    }

    if (!quickviewData || !quickviewData.products) { return; }

    siteGaData.products = quickviewData.products;
    view_item();
}

/**
 * Track add to wishlist ecommerce event
 * Pull data from window.siteGaData
 * @param {String} productID
 */
function add_to_wishlist(event, productID) {
    if (!window.dataLayer || !window.siteGaData || !window.siteGaData.products) { return; }

    var wishlistProducts = siteGaData.products.filter(function(product) {
        return product.id === productID || product.variant === productID;
    });

    if (!wishlistProducts.length) {
        wishlistProducts = siteGaData.products;
    }

    addRemoveCartEvents('add_to_wishlist', wishlistProducts);
}

/**
 * Track add to cart ecommerce event
 * Query the DOM for JSON generated in Cart-AddProduct Controller
 * Use the data to populate the 'products'
 * Add data to the dataLayer which results in a GTM tag being fired
 * @param {String} gaData optional
 */
function add_to_cart(event, gaString) {
    if (!window.dataLayer) { return; }

    var addToCartData = {};

    try {
        addToCartData = JSON.parse(gaString ? gaString : $('.site-ga-addtocartdata').text());
    } catch(e) {
        log('add to cart GA data could not be serialized for analytics');
        return;
    }

    if (!addToCartData || !addToCartData.products) { return; }

    addRemoveCartEvents('add_to_cart', addToCartData.products);
}

/**
 * Track remove from cart ecommerce event
 * Pull data from window.siteGaData
 */
function remove_from_cart() {
    if (!window.dataLayer || !window.siteGaData || !window.siteGaData.removeProducts) { return; }

    addRemoveCartEvents('remove_from_cart', siteGaData.removeProducts);
}

/**
 * Track cart views
 * Pull data from window.siteGaData
 */
function view_cart() {
    if (!window.dataLayer || !window.siteGaData || !window.siteGaData.products) { return; }

    addRemoveCartEvents('view_cart', siteGaData.products);
}

/**
 * Track checkout data
 * Pull data from window.siteGaData
 */
function checkoutStep() {
    if (!window.dataLayer || !window.siteGaData || !window.siteGaData.products || !window.siteGaData.checkout) { return; }

    var productViews = [];
    var value = 0;
    siteGaData.products.forEach(function(product) {
        var productView = getProductView(product);
        value += productView.price * productView.quantity;
        productViews.push(productView);
    });

    var checkoutData = {
        event: '',
        ecommerce: {
            currency: siteGaData.currency,
            value: value,
            coupon: 'coupon' in siteGaData.checkout ? siteGaData.checkout.coupon : '',
            items: productViews
        }
    };

    switch (siteGaData.checkout.actionField.step) {
        case 1:
            checkoutData.event = 'begin_checkout';
            break;
        case 2:
            checkoutData.event = 'add_shipping_info';
            checkoutData.ecommerce.shipping_tier = siteGaData.checkout.shipping || '';
            break;
        case 3:
            checkoutData.event = 'add_payment_info';
            checkoutData.ecommerce.payment_type = siteGaData.checkout.billing || '';
            break;
        default:
            // Do nothing
            break;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push(checkoutData);
}

/**
 * Track products in an order and the order transaction
 * Pull data from window.siteGaData
 * Use that data to populate the 'products' and 'purchase'
 * Add data to the dataLayer which results in a GTM tag being fired
 */
function purchase() {
    if (!window.dataLayer) { log('dataLayer does not exist to push order data to'); }
    if (!window.siteGaData) { log('siteGa data does not exist or could not be serialized'); }

    if (!window.dataLayer || !window.siteGaData) {
        return;
    }

    try {
        var gaData = {
            event: 'purchase',
            ecommerce: {
                currency: siteGaData.currency,
                transaction_id: siteGaData.order.id,
                value: siteGaData.order.revenue,
                coupon: siteGaData.order.coupon || '',
                shipping: siteGaData.order.shipping,
                tax: siteGaData.order.tax,
                items: []
            }
        };

        // any post-processing of the products can be done in
        // this loop before pushing to the dataLayer
        window.siteGaData.products.forEach(function(product) {
            gaData.ecommerce.items.push(getProductView(product));
        });

        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push(gaData);
    } catch(e) {
        if (siteGaData && siteGaData.order && siteGaData.order.id) {
            log('failed to track purchase for orderNo: ' + siteGaData.order.id, e);
        } else {
            log('failed to track purchase', e);
        }
    }
}

/**
 * Track Promotion data
 * - if no data object is passed, scan the DOM for .site-ga-productslotdata
 * - if data is passed, use that to track specific promotions
 * @param {Event} _e Custom Event object
 * @param {Object} data Promotion Data to track - optional
 */
function viewproductslot(_e, data) {
    var eventType;
    if (data) {
        if (data.hasOwnProperty('type')) {
            eventType = data.type === 'promotion' ? 'view_promotion' : 'view_item_list';
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push($.extend({event: eventType}, data));
        return;
    }

    var $slots = $('.site-ga-productslotdata').not('.tracked');
    var $slot;
    var slotData;

    $slots.each(function(i, slot) {
        $slot = $(slot);

        try {
            slotData = JSON.parse($slot.text());
        } catch(e) {
            log('promotion view GA data could not be serialized for analytics');
            return;
        }

        if (slotData.hasOwnProperty('type')) {
            eventType = slotData.type === 'promotion' ? 'view_promotion' : 'view_item_list';
        }
        dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push($.extend({event: eventType}, slotData));

        // add class to prevent duplicate tracking
        // preserve the data for future select_promotion/select_item tracking
        $slot.addClass('tracked');

        // add tracking handles for link click, report that product's data
        // as long as the link or one of its parents was tagged with 'data-itemid'
        var $trackingParent = $slot.parents('[data-analytics-track-selection]:first');
        if ($trackingParent.length) {
            $trackingParent.on('click', 'a', function(e) {

                // get this link's product data
                var itemData = getElementViewItemData($(this), slotData);
                if (!itemData) { return; }

                var eventType = slotData.type === 'promotion' ? 'select_promotion' : 'select_item';
                dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push($.extend(
                    {event: eventType},
                    slotData,
                    { ecommerce: {items: [itemData]} }
                ));
            });
        }

    });
}

/**
 * @private
 * Handle add/remove/view cart and add to wishlist events
 * @param {String} eventName dataLayer event name
 * @param {Array} products list of products to send
 */
function addRemoveCartEvents(eventName, products) {
    if (!siteGaData || !products) { return; }

    var productViews = [];
    var value = 0;
    products.forEach(function(product) {
        var productView = getProductView(product);
        value += productView.price * productView.quantity;
        productViews.push(productView);
    });

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: eventName,
        ecommerce: {
            currency: siteGaData.currency,
            value: value,
            items: productViews
        }
    });
}

/**
 * @private
 * @param {jQuery} $el jQuery element to get data from (or its parents)
 * @param {Object} viewItemData GA data with items[] array
 * @returns {Object|undefined} Ga data for the item, undefined if an item could not be found
 */
function getElementViewItemData($el, viewItemData) {
    var itemID = $el.data('itemid') || $el.parents('[data-itemid]:first').data('itemid');
    if (!itemID) { return undefined; }

    itemID = itemID.toString();

    for (var i = 0; i < viewItemData.items.length; i++) {
        if (viewItemData.items[i].item_id === itemID || viewItemData.items[i].variant === itemID) {
            return viewItemData.items[i];
        }
    }

    return undefined;
}

/**
 * @private
 * Get product information to pass to GA
 * @param {Object} product
 * @returns {Object}
 */
function getProductView(product) {
    if (!product) { return {}; }

    var categories = product.category.split(' / ');
    var price = parseFloat(product.price);
    var productView = {
        item_id: product.id,
        item_name: product.name,
        currency: siteGaData.currency,
        index: 0,
        item_brand: product.brand || '',
        item_category: categories[0],
        item_variant: product.variant,
        price: !isNaN(price) ? price : product.price,
        quantity: product.quantity || 1
    };

    for (var catIndex = 1; catIndex < categories.length && catIndex <= 5; catIndex++) {
        productView['item_category' + (catIndex + 1)] = categories[catIndex];
    }

    if (product.list) {
        productView.item_list_name = product.list;
    }

    return productView
}

/**
 * @private
 * Get a unique ID of the product impression
 * This is a utility function to use when determining when to report
 * impressions and when to filter out duplicates
 * @param {Object} product impression object to generate unique ID
 */
function getImpressionUniqueID(product) {
    return product.variant.length ? product.variant : product.id;
}

/**
 * @private
 * Utility log message, send to server to log
 * @param {String} msg message to send to log
 * @param {Object} data object
 * @param {String} level 'warn', 'error', or 'debug'
 */
function log(msg, data, level) {
    var payload = {
        msg: msg,
        data: JSON.stringify(data) || '',
        level: level || 'warn'
    };

    $.ajax({
        method: 'POST',
        dataType: 'json',
        url: window.siteGaData.resources.logUrl,
        async: true,
        data: payload
    });
}

module.exports = {
    view_item_list: view_item_list,
    productTilesAjax: productTilesAjax,
    select_item: select_item,
    view_item: view_item,
    quickview: quickview,
    add_to_wishlist: add_to_wishlist,
    add_to_cart: add_to_cart,
    remove_from_cart: remove_from_cart,
    view_cart: view_cart,
    checkoutStep: checkoutStep,
    purchase: purchase,
    viewproductslot: viewproductslot
};

},{}],3:[function(require,module,exports){
'use strict';

var $doc;

// sfccGa will refer to Universal Analytics
// ga4 will refer to Google Analytics 4
var sfccGa = require('./dataLayer');
var ga4 = require('./ga4');

/**
 * Get the current page type as set from app.js
 * Fallback to the pageContext set in page decorators
 * @returns {String|Boolean} of page type, false if no page type defined
 */
function getPageType() {
    var pageType = 'app' in window && 'page' in app && app.page.type;
    if (!pageType) {
        pageType = 'pageContext' in window && 'type' in pageContext && pageContext.type;
    }

    return pageType;
}

/**
 * Setup Universal Analytics tracking, dataLayer, and page view events
 * Relies on the SitePreference: googleAnalyticsVersionsEnabled
 */
function initUniversalAnalytics() {
    // exit early if not enabled
    if (window.siteGaData.versionsEnabled.indexOf('ua') === -1) { return; }

    $doc.on('ga.producttiles', sfccGa.productTiles);
    $doc.on('ga.producttilesajax', sfccGa.productTilesAjax);
    $doc.on('ga.producttile.click', sfccGa.productTileClick);
    $doc.on('ga.productdetail', sfccGa.productDetail);
    $doc.on('ga.addtocart', sfccGa.addToCart);
    $doc.on('ga.removefromcart', sfccGa.removeFromCart);
    $doc.on('ga.checkoutstep', sfccGa.checkoutStep);
    $doc.on('ga.purchase', sfccGa.purchase);

    // Trigger an action on page load
    var pageType = getPageType();
    if (pageType) {
        window.siteGaData.pageType = pageType;

        switch (pageType) {
            case 'orderconfirmation':
                $doc.trigger('ga.purchase');
                break;
            default:
                // Do nothing
                break;
        }
    }
}

/**
 * Setup Google Analytics 4 tracking, dataLayer, and page view events
 * Relies on the SitePreference: googleAnalyticsVersionsEnabled
 */
function initGoogleAnalytics4() {
    // exit early if not enabled
    if (window.siteGaData.versionsEnabled.indexOf('ga4') === -1) { return; }

    $doc.on('ga.producttiles', ga4.view_item_list);
    $doc.on('ga.producttilesajax', ga4.productTilesAjax);
    $doc.on('ga.producttile.click', ga4.select_item);
    $doc.on('ga.productdetail', ga4.view_item);
    $doc.on('ga.addtowishlist', ga4.add_to_wishlist);
    $doc.on('ga.quickview', ga4.quickview);
    $doc.on('ga.addtocart', ga4.add_to_cart);
    $doc.on('ga.removefromcart', ga4.remove_from_cart);
    $doc.on('ga.cart', ga4.view_cart);
    $doc.on('ga.checkoutstep', ga4.checkoutStep);
    $doc.on('ga.purchase', ga4.purchase);
    $doc.on('ga.viewproductslot', ga4.viewproductslot);


    // Report all slot list/promotion on page load
    $doc.trigger('ga.viewproductslot');

    // Trigger an action on page load
    var pageType = getPageType();
    if (pageType) {
        window.siteGaData.pageType = pageType;

        switch (pageType) {
            case 'Cart':
                $doc.trigger('ga.cart');
                break;
            case 'orderconfirmation':
                $doc.trigger('ga.purchase');
                break;
            default:
                // Do nothing
                break;
        }
    }

}

$(function() {
    $doc = $(document);
    var siteGaData = {};

    try {
        siteGaData = JSON.parse($('.site-ga-data').text());
    } catch(e) {
        window.console.warn('site GA data could not be serialized for analytics');
        return;
    }

    window.siteGaData = $.extend(window.siteGaData, siteGaData);

    initUniversalAnalytics();
    initGoogleAnalytics4();
});

},{"./dataLayer":1,"./ga4":2}]},{},[3]);
